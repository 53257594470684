<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import ChildrenDependantsMortgage
  from "../../../components/common/questionnaires/fieldsets/mortgage/ChildrenDependantsMortgage";
import RelationshipMortgage from "@/components/common/questionnaires/fieldsets/mortgage/RelationshipMortgage";
import AssetsMortgage from "../../../components/common/questionnaires/fieldsets/mortgage/AssetsMortgage";
import ContactEstatePlanning from "@/components/common/questionnaires/fieldsets/estatePlanning/ContactEstatePlanning";
import NameStatusEstatePlanning
  from "@/components/common/questionnaires/fieldsets/estatePlanning/NameStatusEstatePlanning";
import AddressEstatePlanning from "@/components/common/questionnaires/fieldsets/estatePlanning/AddressEstatePlanning";
import PoliciesEstatePlanning from "@/components/common/questionnaires/fieldsets/estatePlanning/PoliciesEstatePlanning";
import LiabilitiesEstatePlanning
  from "@/components/common/questionnaires/fieldsets/estatePlanning/LiabilitiesEstatePlanning";

export default {
  name: "InsuranceFactFind",
  mixins: [loadHelpers],
  components: {
    ContactEstatePlanning,
    NameStatusEstatePlanning,
    ChildrenDependantsMortgage,
    QuestionnaireBase,
    AddressEstatePlanning,
    AssetsMortgage,
    PoliciesEstatePlanning,
    LiabilitiesEstatePlanning,
    RelationshipMortgage
  },
  data() {
    return {
      name: 'InsuranceFactFind',
      title: "Insurance Pre-Consultation Questions",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'NameStatusEstatePlanning',
        'RelationshipMortgage',
        'ContactEstatePlanning',
        'AddressEstatePlanning',
        'ChildrenDependantsMortgage',
        'AssetsMortgage',
        'PoliciesEstatePlanning',
        'LiabilitiesEstatePlanning'
      ],
      expiryAccessCodeOnFinish: true

    };
  }
};
</script>
