<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Liabilities</h3>

    <LiabilitiesMortgageYN
        v-model="form.mortgage_debts"
        :liabilities="form.liabilities_mortgages"/>
    <div v-if="form.mortgage_debts"
         class="question-box with-arrow">
      <LiabilitiesMortgages
          ref="liabilitiesMortgages"
          v-model="form.liabilities_mortgages"
          type="estatePlanning"
          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesLoansYN
        v-model="form.unsecured_loans"
        :liabilities="form.liabilities_loans"/>
    <div
        v-if="form.unsecured_loans"
        class="question-box with-arrow">
      <LiabilitiesLoans
          ref="liabilitiesLoans"
          v-model="form.liabilities_loans"
          type="estatePlanning"

          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesCreditCardsYN
        v-model="form.credit_card_balances"
        :liabilities="form.liabilities_credit_cards"/>
    <div
        v-if="form.credit_card_balances"
        class="question-box with-arrow">
      <LiabilitiesCreditCards
          ref="liabilitiesCreditCards"
          v-model="form.liabilities_credit_cards"
          type="estatePlanning"
          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesOtherYN
        v-model="form.other_credit_agreements"
        :liabilities="form.liabilities_other"/>
    <div
        v-if="form.other_credit_agreements"
        class="question-box with-arrow">
      <LiabilitiesOther
          ref="liabilitiesOther"
          v-model="form.liabilities_other"
          type="estatePlanning"
          @refresh="refreshLiabilities"/>
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import LiabilitiesMortgageYN from "../../question/questions/clientProfile/liabilities/LiabilitiesMortgageYN";
import LiabilitiesCreditCardsYN from "../../question/questions/clientProfile/liabilities/LiabilitiesCreditCardsYN";
import LiabilitiesLoansYN from "../../question/questions/clientProfile/liabilities/LiabilitiesLoansYN";
import LiabilitiesOtherYN from "../../question/questions/clientProfile/liabilities/LiabilitiesOtherYN";
import LiabilitiesMortgages from "../../question/questions/clientProfile/liabilities/LiabilitiesMortgages";
import LiabilitiesOther from "../../question/questions/clientProfile/liabilities/LiabilitiesOther";
import LiabilitiesCreditCards from "../../question/questions/clientProfile/liabilities/LiabiltiesCreditCards";
import LiabilitiesLoans from "../../question/questions/clientProfile/liabilities/LiabilitiesLoans";

export default {
  name: 'LiabilitiesEstatePlanning',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    LiabilitiesLoans,
    LiabilitiesCreditCards,
    LiabilitiesOther,
    LiabilitiesMortgages,
    LiabilitiesOtherYN,
    LiabilitiesLoansYN,
    LiabilitiesCreditCardsYN,
    LiabilitiesMortgageYN

  },
  data() {
    return {
      label: 'Liabilities' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshLiabilities() {
      // use only one liabilities question to refresh them all
      if ('liabilitiesMortgages' in this.$refs) this.$refs.liabilitiesMortgages.get()
      if ('liabilitiesLoans' in this.$refs) this.$refs.liabilitiesLoans.get()
      if ('liabilitiesCreditCards' in this.$refs) this.$refs.liabilitiesCreditCards.get()
      if ('liabilitiesOther' in this.$refs) this.$refs.liabilitiesOther.get()
    }
  }
};
</script>
