<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :args="args"
        :server-errors="serverErrors"
        :options="booleanYesNo"
        :no-save="false"
        :db="db"
        :disabled="!!policies.length"
        @jsErrors="jsErrors=$event"
        ref="input"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'PoliciesOverYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    policies: {
      type: Array,
      required: true
    }
  },
  mounted () {
    // set initial question value (yes/no) based on policies
    if (this.policies.length && !this.data) {
      this.$refs.input.data = true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Do you have any other policies?',
        subTitle:  '',
        tip: null,
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'policies_other_type'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>