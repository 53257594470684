<template>
  <QuestionBase :question="question"  :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard
            v-for="(policy, index) in data"
            v-model="data[index]"
            :ref="'policy_other' + index"
            :id="'policy_other' + index"
            :key="'policy_other' + policy.id"
            :title="`${policy.policyType}`"
            type="policy-other"
            :loading="loading"
            @delete="deleteObject(policy.id)"
            @save="saveObject(null, 'policy_other' + index)"
        >
          <Component :is="formType" v-model="data[index]" :objectId="policy.id" :key="'policy-form' + policy.id" />
        </ObjectCard>

        <ObjectCard
            v-if="form"
            v-model="form"
            type="policy-add"
            title="New policy"
            :loading="loading"
            @delete="form=null"
            @save="saveObject(form, db.saveField)"
        >
          <Component :is="formType" v-model="form" :noSave="true" :errors-post="errorsPost" />

        </ObjectCard>

          <b-button
              v-if="!form"
              @click="addPolicy"
              class="btn-question w-100"
          >
            <i class="i-Letter-Open text-25 font-weight-800 mr-2">
            </i>
            Add Policy
          </b-button>
      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import { objectSaveHelpers} from "../../objects/objectSaveHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { blankForms } from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PolicyFull from "../../../../fieldsets/sub/policy/PolicyFull";
import PolicyMortgage from "../../../../fieldsets/sub/policy/PolicyMortgage";
import PolicyEstatePlanning from "../../../../fieldsets/sub/policy/PolicyEstatePlanning";

export default {
  name: 'PoliciesOther',
  components: {PolicyFull, ObjectCard, QuestionBase, PolicyEstatePlanning, PolicyMortgage},
  mixins: [objectSaveHelpers, questionLoadHelpers, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    },
    title: {
      type: String,
      default: 'Policies'
    },
    subTitle: {
      type: String,
      default: 'Please add a few details for each of your policies.'
    },
    tip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      question: {
        title:  this.title,
        subTitle:  this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'policies_other',
        deleteLocation: 'client_policy',
        loadLocation: 'client_policies_other',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    formType () {
      if (this.type === 'mortgage') return 'PolicyMortgage'
      if (this.type === 'estatePlanning') return 'PolicyEstatePlanning'
      return 'PolicyFull'
    }
  },
  methods: {
    addPolicy() {
      this.form = null
      this.form = Object.assign({}, this.policyBlankForm)
    },
  }
}
</script>
