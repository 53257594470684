<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Information</h3>
    <MobileNumber v-model="form.profileMobileNumber" />
    <Email v-model="form.profileEmail" />
    <MarketingConsent v-model="form.profileMarketingConsent" :consent-date="form.profileMarketingConsentDate" />
  </div>
</template>

<script>
import { fieldsetHelpers } from "../fieldsetHelpers";

import MobileNumber from "../../question/questions/clientProfile/contact/MobileNumber";
import Email from "../../question/questions/clientProfile/contact/Email";
import MarketingConsent from "../../question/questions/clientProfile/contact/Marketing";

export default {
  name: 'ContactEstatePlanning',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    MarketingConsent,
    Email,
    MobileNumber
  },
  data () {
    return {
      label: 'Contact Details' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
};
</script>
