<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Name</h3>

    <Title v-model="form.profileNameTitle"/>
    <FullName :value-first="form.profileNameFirst"
              :value-middle="form.profileNameMiddle"
              :value-last="form.profileNameLast"
              @inputFirst="form.profileNameFirst=$event"
              @inputMiddle="form.profileNameMiddle=$event"
              @inputLast="form.profileNameLast=$event"/>

    <AliasNameYN v-model="form.profileAliasYN"/>
    <div v-if="form.profileAliasYN" class="question-box with-arrow">
      <AliasName v-model="form.profileAlias"/>
    </div>

    <h3 class="fieldset-heading">Status</h3>

    <Gender v-model="form.profileSex"/>
    <Dob v-model="form.profileDOB"/>

    <Nationality v-model="form.profileNationality"/>
    <div v-if="form.profileNationality && !['British'].includes(form.profileNationality)"
         class="question-box with-arrow">
      <UKSince v-model="form.profileUKSince"/>
      <CountryBorn v-model="form.profileCountryBorn"/>
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {statusOptions} from "../../question/options/statusOptions";
import {personalDetails} from "@/components/common/questionnaires/question/options/personalDetails";

import Title from "../../question/questions/clientProfile/name/Title";
import FullName from "../../question/questions/clientProfile/name/FullName";
import Nationality from "../../question/questions/clientProfile/status/Nationality";
import Dob from "../../question/questions/clientProfile/status/Dob";
import Gender from "../../question/questions/clientProfile/status/Gender";
import AliasNameYN from "../../question/questions/clientProfile/name/AliasNameYN";
import AliasName from "../../question/questions/clientProfile/name/AliasName";
import UKSince from "../../question/questions/clientProfile/status/UKSince";
import CountryBorn from "../../question/questions/clientProfile/status/CountryBorn";


export default {
  name: 'NameStatusEstatePlanning',
  mixins: [fieldsetHelpers, statusOptions, personalDetails],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CountryBorn,
    UKSince,
    AliasName,
    AliasNameYN,
    Gender,
    Dob,
    Nationality,
    FullName,
    Title
  },
  data() {
    return {
      label: 'Name & Status', // used for steps progress
      videoUrl: null
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
